import tw from "tailwind-styled-components";
import styled from "styled-components";

export const BigCardMediaWrapper = tw.div`
  flex
  flex-col
  gap-4

  desktop:gap-6
`;

export const PersonsWrapper = tw.div`
  flex
  flex-col
  gap-4

  desktop:grid
  desktop:grid-cols-3
  desktop:gap-6
`;

export const LinkWrapper = tw.div`
  flex
  flex-row
  justify-center
  items-center
`;

export const Description = styled.div`
  font-size: var(--textS);
  line-height: var(--lineHeightS);

  p {
    margin: 0;
  }

  @media screen and (min-width: 1128px) {
    font-size: var(--textM);
    line-height: var(--lineHeightM);
  }
`;
