import dynamic from "next/dynamic";
import React from "react";
import { Section } from "../index";
import {
  BigCardMediaWrapper,
  PersonsWrapper,
  LinkWrapper,
  Description,
} from "./styles";
import EditorJSContent from "../../editor-js-content";

const PersonDetail = dynamic(() =>
  import("../../elements/person-detail/person-detail")
);
const BigCardMediaItem = dynamic(() =>
  import("../../elements/big-card-media-item/big-card-media-item")
);
const Link = dynamic(() => import("../../elements/link/link"));

export const BigCardMedia = ({
  title,
  sectionSubtitle,
  hideLineSeparation,
  description,
  bigCardMediaItem,
  persons,
  button,
  displaySetting
}) => {
  return (
    <Section
      title={title}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <BigCardMediaWrapper>
        {description && (
          <Description>
            <EditorJSContent content={description} />
          </Description>
        )}
        <BigCardMediaItem {...bigCardMediaItem} />
        <PersonsWrapper>
          {persons &&
            persons.map((person, index) => (
              <PersonDetail {...person} key={index} />
            ))}
        </PersonsWrapper>
        {button && (
          <LinkWrapper>
            <Link type="buttonPrimary" {...button} />
          </LinkWrapper>
        )}
      </BigCardMediaWrapper>
    </Section>
  );
};

export default BigCardMedia;
